<template>
    <div class="liammin-navigation-group-item">
      <div class="liammin-navigation-item-wrapper" v-bind:class="subtitleClass">
        <div class="liammin-navigation-item">
              <div class="liammin-navigation-item-title-wrapper">
                  <div class="liammin-navigation-item-title">
                      <span>{{ title }}</span>
                  </div>
                  <div v-if="hasSubtitle" class="liammin-navigation-item-subtitle">
                      <span>{{ subtitle }}</span>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <slot></slot>
</template>

<script>
export default {
  name: 'NavigationGroupItem',
  data: function () {
    return {
      hasSubtitle: this.subtitle.length > 0, 
      subtitleClass: this.hasSubtitle ? 'liammin-navigation-item-has-subtitle' : ''
    }
  },
  props: {
    title: { 
      type: String, 
      default: 'Untitled Group'
    },
    subtitle: { 
      type: String,
      default: ''
    }
  }
}
</script>
