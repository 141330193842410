<template>
    <label class="flex justify-start items-start">
    <div class="bg-white border-2 rounded border-gray-400 w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500">
        <input type="checkbox" class="opacity-0 absolute" :name="name" :checked="checked" @change="$emit('checked', $event.target.checked)">
        <svg class="fill-current hidden w-3 h-3 text-green-500 pointer-events-none" viewBox="0 0 20 20"><path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/></svg>
    </div>
    <div class="select-none" :class="labelClass">
        <slot></slot>
    </div>
    </label>    
</template>

<style>
    input:checked + svg {
        display: block !important;
    }
</style>

<script>
    export default {
        name: 'Checkbox',
        props: {
            labelClass: { 
                type: String, 
                default: ''
            },
            name: { 
                type: String, 
                default: ''
            },
            checked: { 
                type: Boolean,
                default: false
            },
            value: { 
                type: String,
                default: ''
            }
        }
    }
</script>