<template>      
  <router-view/>
</template>

<script>

export default {
  computed : {
      isLoggedIn : function() { return this.$store.getters.isAuthenticated }
    },
    methods: {
      async logout () {
        await this.$store.dispatch('auth/logout')
        this.$router.push('/login')
    }
  },
}
</script>