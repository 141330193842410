<template>
    <div class="relative">
        <button class="p-2" @click="toggleMenu">
            <slot name="button">
                <i v-bind:class="iconClass" class="232ers"></i>
            </slot>
        </button>
        <div v-bind:class="[showMenu ? 'sm:block' : 'hidden', menuClass ? menuClass : '']" class="absolute right-0 w-40 mt-2 py-2 bg-white border rounded shadow-xl" style="-webkit-animation: fadeIn 750ms; animation: fadeIn 750ms;">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ButtonMenu',
    props: { 
        'menuClass': { 
            type: String, 
            default: ''
        },
        'iconClass': { 
            type: String,
            default: 'fas fa-ellipsis-v'
        }
    },
    data: function () {
        return {
            showMenu: false 
        }
    },
    methods: { 
        toggleMenu: function() { 
            this.showMenu = !this.showMenu;
        }
    }
}
</script>
