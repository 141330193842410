import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'), 
    meta: { requiresAuth: true }
  },
  {
    path: '/fee-calculator',
    name: 'FeeCalculator',
    component: () => import('../views/FeeCalculator.vue'), 
    meta: { requiresAuth: true }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'), 
    meta: { requiresAuth: true }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue'), 
    meta: { requiresAuth: true }
  },
  {
    path: '/register',
    name: "Register",
    component: () => import('../views/Register.vue'), 
    meta: { guest: true },
  },
  {
    path: '/login',
    name: "Login",
    component: () => import('../views/Login.vue'), 
    meta: { guest: true },
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import('../views/errors/404.vue'), 
    meta: { guest: true },
  },
  {
    path: "/no-access",
    name: "NoAccess",
    component: () => import('../views/errors/NoAccess.vue'), 
    meta: { guest: true },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const authRequired = (to.matched.some((record) => record.meta.requiresAuth));
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if ( authRequired && !loggedIn ) {
    next('/login');
  } 
  
  if ( authRequired ) {
    window.axios.get('/access').then(response => {
      if ( response && response.data && response.data.test ) { 
        next()
      } else { 
        next('/no-access');
      }
    });
  } else { 
    next();
  }
});

export default router
