<template>
      <navigation-menu ref="navigationMenu" />
      <div class="flex flex-col flex-auto w-full min-w-0">
          <top-navigation />
          <main>
            <div class="flex flex-col flex-auto">
              <div class="flex flex-col flex-auto w-full p-2 md:py-8 md:px-4">
                  <slot name="content"></slot>
              </div>
            </div>
          </main>
      </div>
</template>

<script>
import NavigationMenu from '@/components/Template/Navigation/NavigationMenu.vue'
import TopNavigation from '@/components/Template/Navigation/TopNavigation.vue'

export default {
  name: 'DashboardTemplate',
  components: {
    TopNavigation,
    NavigationMenu,
  },
  created() {
			document.getElementsByTagName('body')[0].classList.remove('dark');
	}
}
</script>