import Vuex from 'vuex';
import { auth } from './auth.module';

// Create store
export default new Vuex.Store({
  modules: {
    auth
  }
});

