<template>
  <div id="navigationMenu" class="dark bg-gray-900" v-bind:class="{ 'liammin-navigation-opened': isOpen }" style="visibility: visible;">
    <div class="liammin-navigation-wrapper">
			<div class="liammin-navigation-header"></div>
        <perfect-scrollbar class="liammin-navigation-content" ref="scrollbar">
				<!-- <div class="liammin-navigation-content ps ps--active-y"> -->
              
              <navigation-header />

              <!-- Menu Items -->
              <navigation-group-item v-for="group of groupItems" :key="group.id" :title="group.title" :subtitle="group.subtitle">
                <navigation-basic-item v-for="entry of group.items" :key="entry.id" :icon="entry.icon" :title="entry.title" :subtitle="entry.subtitle" :to="entry.to" />
              </navigation-group-item>

              <!-- 

              <navigation-group-item title="Dashboards" subtitle="Unique dashboard designs">
                <navigation-basic-item active icon="far fa-money-bill-alt" title="Finance" />
                <navigation-basic-item icon="fas fa-chart-pie" title="Analytics" />
                <navigation-basic-item icon="fab fa-bitcoin" title="Crypto" />
              </navigation-group-item>
              
              <navigation-group-item title="Applications" subtitle="Custom made application designs">
                <navigation-basic-item icon="far fa-calendar-alt" title="Calendar" subtitle="3 upcoming events" />
                <navigation-basic-item icon="fas fa-user-friends" title="Contacts" />
                <navigation-basic-item icon="fas fa-shopping-basket" title="ECommerce" />
                <navigation-basic-item icon="far fa-envelope" title="Mailbox" />
                <navigation-basic-item icon="far fa-check-circle" title="Tasks" />
              </navigation-group-item>

              <navigation-group-item title="Pages" subtitle="Custom made page designs">
                <navigation-basic-item icon="fas fa-unlock-alt" title="Authentication" />
                <navigation-basic-item icon="far fa-clock" title="Coming soon" />
              </navigation-group-item>

              -->

              <navigation-footer />
              
          </perfect-scrollbar>
        </div>
    </div>
</template>

<script>
import NavigationHeader from '@/components/Template/Navigation/NavigationHeader.vue'
import NavigationFooter from '@/components/Template/Navigation/NavigationFooter.vue'

import NavigationGroupItem from '@/components/Template/Navigation/NavigationGroupItem.vue'
import NavigationBasicItem from '@/components/Template/Navigation/NavigationBasicItem.vue'
//import NavigationDividerItem from '@/components/NavigationDividerItem.vue'


export default {
  name: 'NavigationMenu',
  components: {
    NavigationHeader,
    NavigationFooter,
    NavigationGroupItem,
    NavigationBasicItem,
    //NavigationDividerItem, 
  },
  data: function () {
    return {
      isOpen: false, 
      groupItems: [], 
      errors: [], 
    }
  },
  methods: { 
    toggleNavMenu: function() { 
      this.isOpen = !this.isOpen;
    },
    windowResized: function() { 
      if (window.innerWidth < 960) this.isOpen = false;
      if (window.innerWidth >= 960) this.isOpen = true;
    }
  },
  created() {
    window.axios.get('/menuItems').then(response => {
      this.groupItems = response.data;
    })
    .catch(e => {
      this.errors.push(e);
    });
    window.addEventListener("resize", this.windowResized);
    this.windowResized();
  },
  unmounted() {
    window.removeEventListener("resize", this.windowResized);
  }
}
</script>

<style scoped>

</style>