
import axios from 'axios';
import { createApp } from 'vue';
import App from './App.vue';
import Vuex from 'vuex';
import router from './router';
import store from './store';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
//import Swal from 'sweetalert2';

// Main templates
import DashboardTemplate from '@/components/Template/DashboardTemplate.vue'

// Core components always used
import TextInput from '@/components/Template/TextInput.vue'
import Button from '@/components/Template/Button.vue'
import ButtonMenu from '@/components/Template/ButtonMenu.vue'
import Checkbox from '@/components/Template/Checkbox.vue'

// Tailwind CSS
import '@/assets/tailwind.css'

const app = createApp(App).use(store).use(router).use(PerfectScrollbar).use(Vuex);
app.component('DashboardTemplate', DashboardTemplate);
app.component('TextInput', TextInput);
app.component('Button', Button);
app.component('ButtonMenu', ButtonMenu);
app.component('Checkbox', Checkbox);
app.mount('#app');

// Axios
axios.defaults.baseURL = 'https://my.etsy-tools.co.uk/api/';
axios.interceptors.request.use(function (config) {
	const token = store.state.auth.isLoggedIn ? store.state.auth.user.token : '';
	//console.log( 'Token in the store is: ' + token );
	config.headers.Authorization = token;
	return config;
});

if ( store.state.auth.status.loggedIn )
{
	axios.interceptors.response.use(undefined, function (error) {
		if (error) {
			const originalRequest = error.config;
			//console.log( error );
			console.log(error.status);
			if (error.response.status === 401 && !originalRequest._retry) {
					originalRequest._retry = true;
					store.dispatch('auth/logout')
					router.push('/login')
			}
		}
	});
}

window.axios = axios;
window.currentUser = store.state.auth.user;