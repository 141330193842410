<template>
    <div class="liammin-navigation-content-footer">
		<div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-2 mb-4 opacity-12">
			<img src="@/assets/logo/logo-text-on-dark.svg" class="w-10">
		</div>
	</div>
	<div class="ps__rail-x" style="left: 0px; bottom: 0px;">
		<div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
	</div>
	<div class="ps__rail-y" style="top: 0px; height: 464px; right: 0px;">
		<div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 73px;"></div>
	</div>
	<div class="liammin-navigation-footer"></div>
</template>