<template>
    <div class="liammin-navigation-basic-item">
      <div class="liammin-navigation-item-wrapper">
        <router-link class="liammin-navigation-item" :class="subtitleClass" :to="to">        
          <div class="liammin-navigation-item-icon pr-4">
            <i :class="icon" class="w-5" />
          </div>
          <div class="liammin-navigation-item-title-wrapper">
            <div class="liammin-navigation-item-title">
              <span>{{ title }}</span>
            </div>
            <div v-if="hasSubtitle" class="liammin-navigation-item-subtitle">
                <span>{{ subtitle }}</span>
            </div>
          </div>
        </router-link>
      </div>
    </div>
</template>

<script>
export default {
  name: 'NavigationBasicItem',
  props: {
    icon: { 
      type: String, 
      default: 'question-circle-regular'
    },
    title: { 
      type: String, 
      default: 'Untitled Item'
    },
    subtitle: { 
      type: String,
      default: ''
    },
    to: { 
      type: String,
      default: '/errors/404'
    }
  },
  data: function () {
    return {
      hasSubtitle: this.subtitle.length > 0, 
      subtitleClass: this.hasSubtitle ? 'liammin-navigation-item-has-subtitle' : ''
    }
  }
}
</script>