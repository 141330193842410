<template>
    <button class="py-2 px-4 rounded outline-none">
        <slot>Button</slot>        
    </button>
</template>

<script>
export default {
  name: 'Button'
}
</script>