<template>
    <input
        :value="modelValue"
        class="mb-3 outline-none shadow bg-transparent appearance-none border border-gray-500 rounded w-full py-2 px-3 leading-tight"
        :name="name"
        :type="type"
        :placeholder="placeholder"        
        :required="required ? true : false"
        @input="$emit('update:modelValue', $event.target.value)"
    />	
</template>

<script>
export default {
  name: 'TextInput',
  props: {
    name: { 
      type: String, 
      default: 'input'
    },
    type: { 
      type: String,
      default: 'text'
    },
    placeholder: { 
      type: String,
      default: ''
    },
    required: { 
      type: Boolean,
      default: false
    },
    modelValue: { 
      type: String,
      default: '',
    }
  }
}
</script>

<style scoped>
    input { 
        caret-color: var(--treo-primary);
        border-color: var(--treo-border-rgb);
    }
    input:focus, input:focus-visible { 
        border-color: var(--treo-primary) !important;
    }
</style>