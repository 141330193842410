<template>
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-white dark:bg-transparent">
		<button id="hamburgerMenu" v-on:click="toggleNavMenu" class="mat-focus-indicator mat-icon-button mat-button-base">
            <i class="fas fa-bars w-8"></i>
        </button>
        <button v-on:click="logOut" class="mat-focus-indicator mat-icon-button mat-button-base">
            <i class="fas fa-sign-out-alt w-8"></i>
        </button>  
        
        <div class="flex items-center pl-2 ml-auto space-x-2">
            <!-- Search -->
            <!-- Shortcuts -->
            <!-- Messages -->
        </div>
	</div>
</template>

<script>
export default {
    name: 'TopNavigation',
    methods: {
        toggleNavMenu: function() {
            this.$parent.$refs.navigationMenu.toggleNavMenu();
        },
        logOut: function() { 
            this.$root.logout();
        }
    }
}
</script>
